import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Shopify_top from "../images/organic_search/organic-search-01.png"
import Process__Step_Sr1 from "../images/organic_search/result.png"
import Process__Step_Sr2 from "../images/organic_search/keyword.png"
import Process__Step_Sr3 from "../images/organic_search/data.png"
import Retro from "../images/organic_search/seo-audit.jpg"

import Content_strategy from "../images/organic_search/content-marketing-strategy-01.jpg"
import Basel from "../images/organic_search/linking.png"
import {Helmet} from "react-helmet/es/Helmet";

export default class Container extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>CueBlocks Team - Ecommerce Experts behind the Scenes</title>
          <meta name="description"
                content="CueBlocks has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
          <link rel="canonical" href="https://www.cueblocks.com/team.php"/>
          <meta name="description"
                content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
          <meta property="og:site_name" content="Cueblocks"/>
          <meta property="fb:app_id" content="289086684439915"/>
          <meta property="og:url" content="https://www.cueblocks.com/team.php"/>
          <meta property="og:title"
                content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
          <meta property="og:description"
                content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
          <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:description"
                content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
          <meta name="twitter:title"
                content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
          <meta name="twitter:site" content="@cueblocks"/>
          <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
        </Helmet>
        <article className="static_page organic_search">
          <section className="ser_sec_row  creative_design_page ">
            <div className="container">
              <div className="organic-top">
                <div className="ser_sec_row_lt wdt-40 ">
                  <h2 className="heading_main">Organic Search</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      For our team of Organic Search professionals, every business is unique. Each brand has a personality of its own that deserves customized solutions 
                      for all of its needs. This is where we step in with our expertise of over a decade to deliver impeccable technical SEO, user-friendly content, 
                      and natural link acquisition leading to sustainable results hinged on a bespoke SEO strategy that fits your brand’s requirements like a glove.
                    </p>
                  </div>
                </div>
                <div className="ser_sec_row_rt wdt-60 pd_left_40 text-center ">
                  <img
                    src={Shopify_top}
                    alt="Cue Blocks"
                    className="img-responsive"
                  />
                </div>
              </div>
              <div className="border-light"> </div>
            </div>
          </section>

          <section className=" our-process">
            <div className="container">
              <div className="main-heading text-center">
                <h2 className="heading_main">Our process</h2>
              </div>
              <div className="process-steps">
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr1}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>Initial Analysis</h3>
                    <p>
                      An initial in-depth analysis of your website gives us a sneak peek into all the bottleneck concerns that may arise while we whip-up a solution to
                       generate organic traffic to your site resulting in a positive ROI.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr2}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>Keyword Research</h3>
                    <p>
                      Stepping into the shoes of your target audience, we conduct extensive keyword research on what is trending online in the industry, thereby creating 
                      the best-suited strategy for your brand to generate organic traffic.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr3}
                        alt="Cue Blocks"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>Bespoke SEO Strategy</h3>
                    <p>
                      A unique brand demands an exclusive strategy focused on generating a positive ROI. Based on our analysis of your site, we create a tailor-made SEO strategy
                       that caters to and fulfills all of your brand’s needs.  
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics fleex">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={Retro}
                      alt="Cue Blocks"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">SEO Audit</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                     An SEO audit of your website is where we analyze your current keyword strategy, technical health, content, link profile, and competitors,
                      on the basis of which, our team of experts formulates the perfect opportunities to improve your business.
                       <br />
                      <br />
                      Get in touch with us for an opportunity landscape analysis
                      of your business.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics  content-both">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <h2 className="heading_main">Technical SEO</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      You don’t want to be losing money to technical errors on your website. We don’t want you to either. This is why our approach to SEO starts with the
                      basics - securing the technical foundation of your website.
                      <br />
                      <br />
                      We recommend that you leave all your technical SEO
                      concerns to our team of proficient experts.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>

                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">Migration SEO</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      A mismanaged platform migration may flush all your SEO efforts down the drain, resulting in a loss of audience, SERP ranking,
                       and even revenue. Don’t worry! We’ve got your back. Our SEO experts meticulously make the migration process as seamless as it can get.
                        <br />
                      <br />
                      Want to know more? Get in touch with us.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="conpetulatization ">
            <div className="container">
              <div className="all-grows-block">
                <div className="ser_sec_row_lt  wdt-40">
                  <h2 className="heading_main">Content Strategy</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      For our team of professionals, content is certainly king that demands a keyword-oriented strategy that rightfully places it on the throne of SERPs. 
                      Exploring the nitty-gritty of what’s trending, we work with you to design the most optimal content strategy that drives in organic traffic, making 
                      your website the go-to hub for all industry-related information.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
                <div className="ser_sec_row_rt  wdt-60 hide-mob">
                  <img
                    src={Content_strategy}
                    alt="Cue Blocks"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics last_product fleex shadow-img">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={Basel}
                      alt="Cue Blocks"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">Link Acquisition</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Creating a harmonious relationship between content and linkable assets is our prime focus since the SEO landscape is so capricious. The need to understand, create and implement high-quality campaigns serves as the building blocks of your website’s foundation. We personally take care of every SEO strategy that we create for acquiring links on your website.  
                      <br />
                      <br />
                      Need help getting started? Drop us a line.
                    </p>
                  </div>
                  <Link to="/contact" className="btn main_cta">
                    contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </article>
      </Layout>
    )
  }
}
